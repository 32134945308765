import { Container, Flex, FlexProps, useColorModeValue } from '@chakra-ui/react';
import { FunctionComponent } from 'react';

export type AuthContainerProps = FlexProps;

export const AuthContainer: FunctionComponent<AuthContainerProps> = ({ children, ...props }) => (
  <Flex
    bgGradient={useColorModeValue(
      'linear(to-b, brand.500, brand.700)',
      'linear(to-b, brand.600, brand.900)',
    )}
    direction="column"
    minHeight="100vh"
    placeContent="center"
    py={{ base: '0', md: '12' }}
    {...props}
  >
    <Container
      bg="bg-canvas"
      borderRadius={{ base: 'none', md: 'xl' }}
      boxShadow={useColorModeValue({ base: 'none', md: 'xl' }, { base: 'none', md: 'xl-dark' })}
      flex={{ base: 1, md: 0 }}
      maxW={{ base: 'full', md: 'md' }}
      px={{ base: '6', sm: '10' }}
      py="8"
    >
      {children}
    </Container>
  </Flex>
);

AuthContainer.displayName = 'AuthContainer';
