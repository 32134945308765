import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Link,
  Stack,
  StackProps,
  useToast,
} from '@chakra-ui/react';
import { requestPasswordResetLink } from '@src/lib/api';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { FunctionComponent } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';

type HTMLFormPropsWithoutSubmit = Omit<StackProps, 'onSubmit'>;

export type ForgotPasswordFormData = {
  email: string;
};

export type ForgotPasswordFormProps = HTMLFormPropsWithoutSubmit;

export const ForgotPasswordForm: FunctionComponent<ForgotPasswordFormProps> = ({ ...props }) => {
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm<ForgotPasswordFormData>();
  const router = useRouter();
  const toast = useToast();
  const { t } = useTranslation('auth');
  const { t: errorsT } = useTranslation('errors');
  const { isLoading, mutate } = useMutation<any, { code: any }, string>(requestPasswordResetLink, {
    onError: (error) => {
      toast({
        description: errorsT(error.code) || errorsT('default'),
        status: 'error',
      });
    },
    onSuccess: () => {
      toast({
        description: t('components.ForgotPasswordForm.linkSent'),
        status: 'info',
      });
      router.push('/login');
    },
  });

  const onSubmit = (formData: ForgotPasswordFormData) => {
    mutate(formData.email);
  };

  return (
    <Stack as="form" onSubmit={handleSubmit(onSubmit)} spacing="4" {...props}>
      <FormControl isInvalid={!!errors.email}>
        <FormLabel htmlFor="email">
          {t('components.ForgotPasswordForm.fields.email.label')}
        </FormLabel>
        <Input
          id="email"
          type="email"
          {...register('email', {
            required: t('components.ForgotPasswordForm.errors.email.required'),
          })}
        />
        {errors.email?.message && <FormErrorMessage>{errors.email.message}</FormErrorMessage>}
      </FormControl>
      <Button isLoading={isLoading || isSubmitting} type="submit" variant="primary">
        {t('components.ForgotPasswordForm.submit')}
      </Button>
      <Flex justify="center">
        <NextLink href={{ pathname: '/login', query: router.query }}>
          <Link as="span" fontSize="sm">
            {t('components.ForgotPasswordForm.backToLogIn')}
          </Link>
        </NextLink>
      </Flex>
    </Stack>
  );
};

ForgotPasswordForm.displayName = 'ForgotPasswordForm';
