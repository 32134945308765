import { Divider, HStack, Icon, Spinner, Text, VStack, useToast } from '@chakra-ui/react';
import { OAuthButtonGroup, DisplayVariant } from '@src/components/Auth';
import { AuthMethod, OAuthProviderId, useCredentialsPersistence, useAuthMethod } from '@src/hooks';
import { useTranslation } from 'next-i18next';
import { FunctionComponent, useState } from 'react';
import { HiArrowSmLeft } from 'react-icons/hi';
import { LoginPasswordForm, LoginPasswordFormData } from './LoginPasswordForm';

export type PasswordFormData = {
  email: string;
};

export type LoginProvidersProps = {
  allowEmail: boolean;
  onResetUserEmail: () => void;
  providers: OAuthProviderId[];
  redirecting: boolean;
  userEmail: string;
};

export const LoginProviders: FunctionComponent<LoginProvidersProps> = ({
  allowEmail,
  onResetUserEmail,
  providers,
  redirecting,
  userEmail,
}) => {
  const [isRedirecting, setIsRedirecting] = useState(redirecting);
  const { persistence, setPersistence } = useCredentialsPersistence();
  const signInWithEmailAndPassword = useAuthMethod(AuthMethod.EmailAndPassword);
  const signInWithProvider = useAuthMethod(AuthMethod.OAuth);
  const toast = useToast();
  const { t } = useTranslation('auth');
  const { t: errorsT } = useTranslation('errors');

  const onOAuthProviderSelect = async (provider: OAuthProviderId) => {
    try {
      await signInWithProvider(provider);
      setIsRedirecting(true);
    } catch (error) {
      toast({
        description: errorsT(error.message) || errorsT('default'),
        status: 'error',
      });
    }
  };

  const onEmailLoginFormSubmit = async (data: LoginPasswordFormData) => {
    try {
      await signInWithEmailAndPassword(userEmail, data.password, persistence);
      setIsRedirecting(true);
    } catch (error) {
      toast({
        description: errorsT(error.message) || errorsT('default'),
        status: 'error',
      });
    }
  };

  if (isRedirecting) {
    return (
      <VStack align="center" p="10">
        <Spinner color="brand.500" size="lg" />
      </VStack>
    );
  }

  return (
    <>
      <VStack spacing="2">
        <HStack
          _hover={{ color: 'brand.400' }}
          align="center"
          aria-label={t('components.LoginProviders.useDifferentEmail')}
          color="muted"
          cursor="pointer"
          onClick={onResetUserEmail}
          onKeyPress={(e) => e.key === 'Enter' && onResetUserEmail()}
          role="button"
          spacing="2"
          tabIndex={0}
          width="max-content"
        >
          <Icon as={HiArrowSmLeft} color="currentColor" />
          <Text color="currentColor" fontSize="xs">
            {t('components.LoginProviders.useDifferentEmail')}
          </Text>
        </HStack>
        <Text fontSize="sm" textAlign="center">
          {t('components.LoginProviders.loggingInWith', {
            userEmail,
          })}
        </Text>
      </VStack>
      {allowEmail && (
        <LoginPasswordForm
          hasPersistence={persistence}
          onPersistenceChange={setPersistence.toggle}
          onSubmit={onEmailLoginFormSubmit}
        />
      )}
      {allowEmail && providers.length > 0 && (
        <HStack>
          <Divider />
          <Text whiteSpace="nowrap">{t('pages.login.divider')}</Text>
          <Divider />
        </HStack>
      )}
      {providers.length > 0 && (
        <OAuthButtonGroup
          displayVariant={DisplayVariant.IconsWithLabels}
          enabledProviders={providers}
          onProviderSelected={onOAuthProviderSelect}
        />
      )}
    </>
  );
};
