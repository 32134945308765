import { useEnabledLoginProviders, useLoginConfiguration } from '@src/hooks';
import { hasWorkOSProvider } from '@src/lib/api';
import {
  getValidNextUrl,
  getClientId,
  updateLastAuthenticatedEmail,
  getLastAuthenticatedEmail,
  cleanupHiHelloStorage,
} from '@src/lib/utils';
import { useRouter } from 'next/router';
import { FunctionComponent, useEffect, useState } from 'react';
import { LoginEmailForm, LoginEmailFormData } from './LoginEmailForm';
import { LoginProviders } from './LoginProviders';

const encodeUriParam = (param: string) => encodeURIComponent(decodeURIComponent(param));

export const Login: FunctionComponent = () => {
  const [userEmail, setUserEmail] = useState<string>();
  const [lastAuthenticatedEmail, setLastAuthenticatedEmail] = useState<string>();
  const [redirectingToProvider, setRedirectingToProvider] = useState(false);
  const router = useRouter();
  const clientId = getClientId(router.query.clientId?.toString() || '');
  const nextUrl = getValidNextUrl(router.query);

  useEffect(() => {
    setLastAuthenticatedEmail(getLastAuthenticatedEmail());
  }, []);

  const { enabledProviders, isLoading: providersIsLoading } = useEnabledLoginProviders({
    email: userEmail || lastAuthenticatedEmail,
  });
  const { loginMethods: configLoginMethods, isLoading: configIsLoading } = useLoginConfiguration();

  const allowEmail = enabledProviders
    ? !!configLoginMethods?.allowEmail && !!enabledProviders?.allowEmail
    : !!configLoginMethods?.allowEmail;
  const providersToShow = enabledProviders?.providers
    ? configLoginMethods?.providers.filter((value) => enabledProviders?.providers.includes(value))
    : configLoginMethods?.providers;

  const handleEmailSubmit = (formData: LoginEmailFormData) => {
    setUserEmail(formData.email);
    setLastAuthenticatedEmail(formData.email);
  };

  const handleResetUserEmail = () => {
    setUserEmail(undefined);
    setLastAuthenticatedEmail(undefined);
    updateLastAuthenticatedEmail();
    cleanupHiHelloStorage();
  };

  useEffect(() => {
    if (enabledProviders) {
      if (hasWorkOSProvider(enabledProviders)) {
        const email = userEmail || lastAuthenticatedEmail;
        if (email) {
          setRedirectingToProvider(true);
          window.location.href = `${process.env.NEXT_PUBLIC_API_URL}/oauth?email=${encodeUriParam(
            email,
          )}${clientId ? `&clientId=${encodeUriParam(clientId)}` : ''}${
            nextUrl ? `&nextUrl=${encodeUriParam(nextUrl)}` : ''
          }`;
        }
      } else if (lastAuthenticatedEmail && !userEmail) {
        setUserEmail(lastAuthenticatedEmail);
      }
    }
  }, [enabledProviders, userEmail, clientId, nextUrl, lastAuthenticatedEmail]);

  return providersToShow && userEmail ? (
    <LoginProviders
      allowEmail={allowEmail}
      onResetUserEmail={handleResetUserEmail}
      providers={providersToShow}
      redirecting={redirectingToProvider}
      userEmail={userEmail!}
    />
  ) : (
    <LoginEmailForm
      allowEmail={allowEmail}
      isDisabled={providersIsLoading || configIsLoading}
      onSubmit={handleEmailSubmit}
    />
  );
};
