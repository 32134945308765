import { Checkbox, Link } from '@chakra-ui/react';
import { Trans, useTranslation } from 'next-i18next';
import { ChangeEventHandler } from 'react';

type SignupTermsAgreementProps = {
  onAgreeOnTermsChange: (agreed: boolean) => void;
};

export const SignupTermsAgreement = ({ onAgreeOnTermsChange }: SignupTermsAgreementProps) => {
  const { t } = useTranslation('auth');

  const handleAgreeOnTermsChange: ChangeEventHandler<HTMLInputElement> = (event) =>
    onAgreeOnTermsChange(event.target.checked);

  return (
    <Checkbox id="agreement" onChange={handleAgreeOnTermsChange} spacing="1rem">
      <Trans
        components={[
          <Link href={`${process.env.NEXT_PUBLIC_WWW_URL}/legal/terms`} target="_blank" />,
          <Link href={`${process.env.NEXT_PUBLIC_WWW_URL}/legal/aup`} target="_blank" />,
        ]}
        i18nKey="components.SignupForm.fields.agreement.label"
        values={{
          acceptableUsePolicy: t(
            'components.SignupForm.fields.agreement.labelValues.acceptableUsePolicy',
          ),
          termsOfService: t('components.SignupForm.fields.agreement.labelValues.termsOfService'),
        }}
      >
        {t('components.SignupForm.fields.agreement.label')}
      </Trans>
    </Checkbox>
  );
};
