import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Link,
  Stack,
  StackProps,
  Checkbox,
} from '@chakra-ui/react';
import { PasswordInput } from '@hihello/ui';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { ChangeEventHandler, FunctionComponent, useCallback } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';

type HTMLFormPropsWithoutSubmit = Omit<StackProps, 'onSubmit'>;

export type EmailLoginFormData = {
  email: string;
  password: string;
};

export type EmailLoginFormProps = HTMLFormPropsWithoutSubmit & {
  onPersistCredentialsChange: (persist: boolean) => void;
  onSubmit: SubmitHandler<EmailLoginFormData>;
};

export const EmailLoginForm: FunctionComponent<EmailLoginFormProps> = ({
  onPersistCredentialsChange: onPersistenceChanged,
  onSubmit,
  ...props
}) => {
  const { t } = useTranslation('auth');
  const router = useRouter();
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm<EmailLoginFormData>();

  const onPersistChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => onPersistenceChanged(event.target.checked),
    [onPersistenceChanged],
  );

  return (
    <Stack as="form" onSubmit={handleSubmit(onSubmit)} spacing="4" {...props}>
      <FormControl isInvalid={!!errors.email}>
        <FormLabel htmlFor="email">{t('components.EmailLoginForm.fields.email.label')}</FormLabel>
        <Input
          id="email"
          type="email"
          {...register('email', {
            required: t('components.EmailLoginForm.errors.email.required'),
          })}
        />
        {errors.email?.message && <FormErrorMessage>{errors.email.message}</FormErrorMessage>}
      </FormControl>
      <FormControl isInvalid={!!errors.password}>
        <FormLabel htmlFor="password">
          {t('components.EmailLoginForm.fields.password.label')}
        </FormLabel>
        <PasswordInput
          id="password"
          {...register('password', {
            required: t('components.EmailLoginForm.errors.password.required'),
          })}
        />
        {errors.password?.message && <FormErrorMessage>{errors.password.message}</FormErrorMessage>}
      </FormControl>
      <Stack isInline justify="space-between">
        <Checkbox defaultChecked id="persist" onChange={onPersistChange}>
          {t('components.EmailLoginForm.fields.persist.label')}
        </Checkbox>
      </Stack>
      <Button isLoading={isSubmitting} type="submit" variant="primary">
        {t('components.EmailLoginForm.submit')}
      </Button>
      <Flex justify="center">
        <NextLink href={{ pathname: '/forgot-password', query: router.query }}>
          <Link as="span" fontSize="sm">
            {t('components.EmailLoginForm.forgotPassword')}
          </Link>
        </NextLink>
      </Flex>
    </Stack>
  );
};

EmailLoginForm.displayName = 'EmailLoginForm';
