import {
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Link,
  Stack,
  StackProps,
} from '@chakra-ui/react';
import { PasswordInput } from '@hihello/ui';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { FunctionComponent } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

type HTMLFormPropsWithoutSubmit = Omit<StackProps, 'onSubmit'>;

export type LoginPasswordFormData = {
  password: string;
};

export type LoginEmailFormProps = HTMLFormPropsWithoutSubmit & {
  hasPersistence: boolean;
  onPersistenceChange: () => void;
  onSubmit: SubmitHandler<LoginPasswordFormData>;
};

export const LoginPasswordForm: FunctionComponent<LoginEmailFormProps> = ({
  hasPersistence,
  onPersistenceChange,
  onSubmit,
  ...props
}) => {
  const { t } = useTranslation('auth');
  const router = useRouter();

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm<LoginPasswordFormData>();

  return (
    <Stack as="form" onSubmit={handleSubmit(onSubmit)} spacing="4" {...props}>
      <FormControl isInvalid={!!errors.password}>
        <FormLabel htmlFor="password">
          {t('components.LoginPasswordForm.fields.password.label')}
        </FormLabel>
        <PasswordInput
          autoFocus
          id="password"
          {...register('password', {
            required: t('components.LoginPasswordForm.errors.password.required'),
          })}
        />
        {errors.password?.message && <FormErrorMessage>{errors.password.message}</FormErrorMessage>}
      </FormControl>
      <Stack isInline justify="space-between">
        <Checkbox
          checked={hasPersistence}
          defaultChecked={hasPersistence}
          id="persist"
          onChange={onPersistenceChange}
        >
          {t('components.LoginPasswordForm.fields.persist.label')}
        </Checkbox>
      </Stack>
      <Button isLoading={isSubmitting} type="submit" variant="primary">
        {t('components.LoginPasswordForm.submit')}
      </Button>
      <Flex justify="center">
        <NextLink href={{ pathname: '/forgot-password', query: router.query }}>
          <Link as="span" fontSize="sm">
            {t('components.LoginPasswordForm.forgotPassword')}
          </Link>
        </NextLink>
      </Flex>
    </Stack>
  );
};
