import {
  Button,
  ButtonGroup,
  ButtonGroupProps,
  VisuallyHidden,
  useToken,
  Box,
  Grid,
  GridItem,
} from '@chakra-ui/react';
import { IconGoogleGColored } from '@hihello/ui';
import { OAuthProviderId } from '@src/hooks';
import { useTranslation } from 'next-i18next';
import { FunctionComponent } from 'react';
import { AppleIcon, FacebookIcon, MicrosoftIcon } from './OAuthProviderIcons';

const providerList: Record<OAuthProviderId, JSX.Element> = {
  [OAuthProviderId.Apple]: <AppleIcon boxSize="5" />,
  [OAuthProviderId.Facebook]: <FacebookIcon boxSize="5" />,
  [OAuthProviderId.Google]: <IconGoogleGColored boxSize="5" />,
  [OAuthProviderId.Microsoft]: <MicrosoftIcon boxSize="5" />,
  [OAuthProviderId.WorkOS]: <MicrosoftIcon boxSize="5" />,
};

export enum DisplayVariant {
  Icons,
  IconsWithLabels,
  IconsIfMoreThanThree,
}

export type OAuthButtonGroupProps = ButtonGroupProps & {
  displayVariant?: DisplayVariant;
  enabledProviders?: OAuthProviderId[];
  onProviderSelected: (provider: OAuthProviderId) => Promise<void>;
};

export const OAuthButtonGroup: FunctionComponent<OAuthButtonGroupProps> = ({
  enabledProviders,
  onProviderSelected,
  displayVariant = DisplayVariant.Icons,
  ...props
}) => {
  const [space4] = useToken('space', ['4']);
  const { t } = useTranslation('auth');

  if (!enabledProviders) {
    return null;
  }

  const providers = enabledProviders;
  const displayLabels =
    displayVariant === DisplayVariant.IconsWithLabels ||
    (displayVariant === DisplayVariant.IconsIfMoreThanThree && providers.length <= 3);

  const handleButtonClick = (type: OAuthProviderId) => () => {
    onProviderSelected(type);
  };

  const maxW = providers.length < 5 ? `calc(20% - ${space4})` : undefined;

  if (displayLabels) {
    return (
      <Grid gap="2" templateColumns="repeat(auto-fit, minmax(10.625rem, 1fr))" {...props}>
        {providers.map((providerType) => (
          <GridItem key={providerType}>
            <Button
              key={providerType}
              fontSize={12}
              onClick={handleButtonClick(providerType)}
              variant="outline"
              width="full"
            >
              {providerList[providerType]}
              <Box pl="2">
                {t('components.OAuthButtonGroup.displayedLabel', {
                  name: t(`components.OAuthButtonGroup.providers.${providerType}`),
                })}
              </Box>
            </Button>
          </GridItem>
        ))}
      </Grid>
    );
  }

  return (
    <ButtonGroup
      alignItems="center"
      display="flex"
      justifyContent="center"
      spacing="4"
      variant="outline"
      width="full"
      {...props}
    >
      {providers.map((providerType) => (
        <Button
          key={providerType}
          maxW={maxW}
          onClick={handleButtonClick(providerType)}
          width="full"
        >
          <VisuallyHidden>
            {t('components.OAuthButtonGroup.visuallyHiddenLabel', {
              name: t(`components.OAuthButtonGroup.providers.${providerType}`),
            })}
          </VisuallyHidden>
          {providerList[providerType]}
        </Button>
      ))}
    </ButtonGroup>
  );
};
