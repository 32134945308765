import {
  Button,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Stack,
  StackProps,
  useToast,
  HStack,
  Divider,
  Text,
} from '@chakra-ui/react';
import { AuthMethod, OAuthProviderId, useLoginConfiguration, useAuthMethod } from '@src/hooks';
import { useTranslation } from 'next-i18next';
import { FunctionComponent } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { DisplayVariant, OAuthButtonGroup } from '../OAuthButtonGroup';

type HTMLFormPropsWithoutSubmit = Omit<StackProps, 'onSubmit'>;

export type LoginEmailFormData = {
  email: string;
};

export type LoginEmailFormProps = HTMLFormPropsWithoutSubmit & {
  allowEmail: boolean;
  isDisabled: boolean;
  onSubmit: SubmitHandler<LoginEmailFormData>;
};

export const LoginEmailForm: FunctionComponent<LoginEmailFormProps> = ({
  isDisabled,
  onSubmit,
  allowEmail,
  ...props
}) => {
  const { t } = useTranslation('auth');
  const { loginMethods } = useLoginConfiguration();
  const signInWithProvider = useAuthMethod(AuthMethod.OAuth);
  const toast = useToast();
  const { t: errorsT } = useTranslation('errors');

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm<LoginEmailFormData>();

  const onOAuthProviderSelect = async (provider: OAuthProviderId) => {
    try {
      await signInWithProvider(provider);
    } catch (error) {
      toast({
        description: errorsT(error.code) || errorsT('default'),
        status: 'error',
      });
    }
  };

  return (
    <Stack as="form" onSubmit={handleSubmit(onSubmit)} spacing="4" {...props}>
      {allowEmail && (
        <>
          <FormControl isInvalid={!!errors.email}>
            <FormLabel htmlFor="email">
              {t('components.LoginEmailForm.fields.email.label')}
            </FormLabel>
            <Input
              autoFocus
              id="email"
              isDisabled={isDisabled}
              type="email"
              {...register('email', {
                required: t('components.LoginEmailForm.errors.email.required'),
              })}
            />
            {errors.email?.message && <FormErrorMessage>{errors.email.message}</FormErrorMessage>}
          </FormControl>

          <Button isLoading={isSubmitting || isDisabled} type="submit" variant="primary">
            {t('components.LoginEmailForm.submit')}
          </Button>

          <HStack>
            <Divider />
            <Text whiteSpace="nowrap">{t('pages.login.divider')}</Text>
            <Divider />
          </HStack>
        </>
      )}

      <OAuthButtonGroup
        displayVariant={DisplayVariant.IconsWithLabels}
        enabledProviders={loginMethods?.providers}
        onProviderSelected={onOAuthProviderSelect}
      />
    </Stack>
  );
};
