import {
  Button,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Stack,
  StackProps,
} from '@chakra-ui/react';
import { PasswordInput } from '@hihello/ui';
import { useTranslation } from 'next-i18next';
import { FunctionComponent } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { SignupTermsAgreement } from './SignupTermsAgreement';

type HTMLFormPropsWithoutSubmit = Omit<StackProps, 'onSubmit'>;

export type SignupFormData = {
  email: string;
  password: string;
  passwordVerification: string;
};

export type SignupFormProps = HTMLFormPropsWithoutSubmit & {
  onAgreeOnTermsChange: (agreed: boolean) => void;
  onSubmit: SubmitHandler<SignupFormData>;
};

export const SignupForm: FunctionComponent<SignupFormProps> = ({
  onAgreeOnTermsChange,
  onSubmit,
  ...props
}) => {
  const { t } = useTranslation('auth');
  const {
    formState: { errors, isSubmitting },
    getValues,
    handleSubmit,
    register,
  } = useForm<SignupFormData>();

  return (
    <Stack as="form" onSubmit={handleSubmit(onSubmit)} spacing="4" {...props}>
      <FormControl isInvalid={!!errors.email}>
        <FormLabel htmlFor="email">{t('components.SignupForm.fields.email.label')}</FormLabel>
        <Input
          id="email"
          type="email"
          {...register('email', {
            required: t('components.SignupForm.errors.email.required'),
          })}
        />
        {errors.email?.message && <FormErrorMessage>{errors.email.message}</FormErrorMessage>}
      </FormControl>
      <FormControl isInvalid={!!errors.password}>
        <FormLabel htmlFor="password">{t('components.SignupForm.fields.password.label')}</FormLabel>
        <PasswordInput
          id="password"
          {...register('password', {
            required: t('components.SignupForm.errors.password.required'),
            validate: (v) =>
              v.length < 6 ? t('components.SignupForm.errors.password.validate') : true,
          })}
        />
        {errors.password?.message && <FormErrorMessage>{errors.password.message}</FormErrorMessage>}
      </FormControl>
      <FormControl isInvalid={!!errors.passwordVerification}>
        <FormLabel htmlFor="passwordVerification">
          {t('components.SignupForm.fields.passwordVerification.label')}
        </FormLabel>
        <PasswordInput
          id="passwordVerification"
          {...register('passwordVerification', {
            required: t('components.SignupForm.errors.passwordVerification.required'),
            validate: (v) =>
              v !== getValues().password
                ? t('components.SignupForm.errors.passwordVerification.validate')
                : true,
          })}
        />
        {errors.passwordVerification?.message && (
          <FormErrorMessage>{errors.passwordVerification?.message}</FormErrorMessage>
        )}
      </FormControl>
      <Stack isInline justify="space-between">
        <SignupTermsAgreement onAgreeOnTermsChange={onAgreeOnTermsChange} />
      </Stack>
      <Button isLoading={isSubmitting} type="submit" variant="primary">
        {t('components.SignupForm.submit')}
      </Button>
    </Stack>
  );
};
